





































import Vue from 'vue';

export default Vue.extend({
  name: 'DriveOut',

  mounted() {
    this.$api.getStatus().then();
  },
  methods: {
    endParkingEvent() {
      this.$api.endCurrentParkingEvent().then(
        () => this.$changeRoute('ThankYou'),
        () => { /* on failure */ },
      );
    },
  },
});
